<script>
  import { styles } from "../../utils/styles.js";
  import { classFactory } from '../../utils/factories';
  import { beforeUpdate } from 'svelte';
  import { reflex } from '../../utils/styles';

  const { image, borderRadius } = styles;
  export let src = process.env.PLACEHOLDER;
  export let alt = "no label";
  export let size = "normal";
  export let radius = "normal";

  let result = {};
  beforeUpdate(() => {
    result = classFactory(image[size], borderRadius[radius] );
  });
</script>

<img class={result} {src} alt="{alt} cover" />
