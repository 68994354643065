export const BREAKPOINTS = {
	TABLET: 576,
	DESKTOP: 768,
};

// IF NO SETTINGS
export const NO_SETTINGS = {
	is_published: true,
	twitter_account: undefined,
	multi_show: true,
	color: '#72238e',
	smartlink_color: '#72238e',
	recommendedShows: {
		data: [],
	},
};

export const LIST_SOCIALS = ['facebook', 'twitter', 'site', 'instagram', 'linkedin', 'monetization'];

export const BROADCAST_PLATEFORMS = {
	itunes: 'apple podcasts',
	spotify: 'spotify',
	deezer: 'deezer',
	google_podcasts: 'google podcasts',
	podcast_addict: 'podcast addict',
	castbox: 'castbox',
	overcast: 'overcast',
	castro: 'castro',
	youtube: 'youTube',
	soundcloud: 'soundcloud',
	pocket_casts: 'pocket casts',
	stitcher: 'stitcher',
	tunein: 'tuneIn',
	amazon: 'amazon',
	jio_saavn: 'JioSaavn',
	player_fm: 'PlayerFM',
	i_heart_radio: 'iHeartRadio',
	gaana: 'Gaana',
	listen_notes: 'Listen Notes',
	pandora: 'Pandora',
	podchaser: 'Podchaser',
	podcast_index: 'Podcast Index',
	radio_public : 'RadioPublic',
};

export const TAG_NAME = [
	'p',
	'span',
	'div',
	'svg',
	'hr',
	'a',
	'h1',
	'h2',
	'h3',
	'h4',
	'h5',
	'h6',
	'section',
	'article',
	'aside',
	'main',
	'footer',
];

// OBJECT CONFIGURATION POST MESSAGE
export const CONFIG_POST_MESSAGE = {
	source: 'ausha-subscribe-modal',
	name: 'close-subscribe-modal',
	payload: {},
};