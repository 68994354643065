import { BREAKPOINTS } from '../utils/constants';

export const useResponsive = (e) => {
	let media;
	if (e < BREAKPOINTS.TABLET) {
		media = {
			isMobile: true,
			isTablet: false,
			isDesktop: false,
			isMobileOrTablet: true,
			tabletOrDesktop: false,
		};
	} else if (e < BREAKPOINTS.DESKTOP) {
		media = {
			isMobile: false,
			isTablet: true,
			isDesktop: false,
			isMobileOrTablet: true,
			isTabletOrDesktop: true,
		};
	} else {
		media = {
			isMobile: false,
			isTablet: false,
			isDesktop: true,
			isMobileOrTablet: false,
			isTabletOrDesktop: true,
		};
	}
	return media;
};
