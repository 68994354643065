<script>
    import Paper from './Paper.svelte';
    import { inputStore } from '../../stores/ShowStore';
    import { t } from '../../utils/i18n';

    const pattern = '[A-Za-z0-9](([_\.\-]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([_\.\-]?[a-zA-Z0-9]+)*)\.([A-Za-z]{2,})';
</script>

<Paper flex justify=center h=45px w=100% {...$$props}>
    <label for=email>
        <input
                type=email
                name=email
                bind:value={$inputStore}
                placeholder={$t('adresse mail')}
                pattern={pattern}
        />
    </label>
</Paper>
