<script>
    import { beforeUpdate, onMount } from 'svelte';
    import { writable } from 'svelte/store';
    import Modal from './molecules/Modal.svelte';
    import Text from './atoms/Text.svelte';
    import Paper from './atoms/Paper.svelte';
    import Img from './atoms/Img.svelte';
    import Button from './atoms/Button.svelte';
    import {
        responseStatus,
        showStore,
        settingsStore,
        inputStore,
        locationStore,
        fetchBySlug,
        useCloseModal,
    } from '../stores/ShowStore';
    import { extractQuery, updater } from '../utils/helpers';
    import SubscribeValidate from './molecules/SubscribeValidate.svelte';
    import FormSubscription from './molecules/FormSubscription.svelte';
    import SubscriptionPlatform from './molecules/SubscriptionPlatform.svelte';
    import { fetcher } from '../Fetchers/fetcher';
    import { useResponsive } from '../hooks/useResponsive';
    import { t } from '../utils/i18n';

    let width;
    let media = {};
    onMount(() => {
        fetchBySlug(extractQuery(window.location.search));
    });

    beforeUpdate(() => {
        media = useResponsive(width);
    });
</script>

<svelte:window bind:innerWidth={width}/>
<Modal on:close={e => useCloseModal(e)}>

    <Paper flex justify="center" position=absolute left=50% right=50% translateY=-50%>
        <Img
                src={$showStore.image_url}
                alt={$showStore.name}
                size={media.isMobile ? 'small' : 'normal'}
                radius="normal"
        />
    </Paper>

    <Paper px={media.isMobile && '20px'} maxW="450px" w="100%" m="auto">
        <svelte:component this={!$responseStatus ? FormSubscription : SubscribeValidate}/>
    </Paper>
    {#if $settingsStore.subscribe}
        <Paper background="greyLight" align="center" px="20px" py="10px" radB="4px">
            <Text
                    as="span"
                    size="small"
                    color="grey"
                    label={$t("ce service est proposé par Ausha.")}>
                <Text
                        as="span"
                        size="small"
                        label={$t("consultez leur")}
                        color="grey"
                />
                <Text
                        as="a"
                        size="small"
                        label={$t("politique de confidentialité ici.")}
                        color={$settingsStore.color}
                        href="https://fr.ausha.co/cgu"
                />
            </Text>
        </Paper>
    {/if}
</Modal>
