import { writable } from "svelte/store";
import { closeModal, postMessage, updater } from "../utils/helpers";
import { getSettings, getShow } from "../api/api";
import { CONFIG_POST_MESSAGE, NO_SETTINGS } from "../utils/constants";

let res_show = {};
let res_settings_show = {};

export const fetchBySlug = async (query) => {
  const { slug } = query;
  updater(query)(locationStore);

  // FETCH SHOW AND UPDATE IT
  res_show = await getShow({ slug });
  updater(res_show)(showStore);

  // FETCH SETTINGS SHOW AND UPDATE IT
  res_settings_show = await getSettings({
    showId: res_show.id,
    error: NO_SETTINGS,
  });
  updater(res_settings_show)(settingsStore);

  // UPDATE STORE UI
  updater(true)(modalStore);
};

// CLOSE MODAL
export const useCloseModal = (e) =>
  closeModal(modalStore)(postMessage(e)(CONFIG_POST_MESSAGE));
// SHOW
export const showStore = writable({});
// SETTINGS STORE
export const settingsStore = writable({});
// UI
export const responseStatus = writable(false);
export const inputStore = writable("");
export const modalStore = writable(false);
export const locationStore = writable({});
export const isBroadcastPlateform = writable(false);
