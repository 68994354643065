import { dictionary, isLoading, locale, t, init, getLocaleFromNavigator } from 'svelte-i18n';

// source => https://medium.com/i18n-and-l10n-resources-for-developers/how-to-localize-a-svelte-app-with-svelte-i18n-9e86cc9eb727
init({
	fallbackLocale: 'en',
	initialLocale: getLocaleFromNavigator(),
});

const MESSAGE_FILE_URL_TEMPLATE = `../../lang/${locale}.json`;

function setupI18n({ withLocale: _locale } = { withLocale: 'fr' }) {
	const lang = _locale.match(/(fr|en)/g) ? _locale.match(/(fr|en)/g)[0] : 'en';
	const messsagesFileUrl = MESSAGE_FILE_URL_TEMPLATE.replace(`${locale}`, lang);

	return fetch(messsagesFileUrl)
		.then(response => response.json())
		.then((messages) => {
			dictionary.set({ [_locale]: messages });
			locale.set(_locale);
		});

}

export { t, setupI18n, isLoading };