<script>
    import { beforeUpdate } from 'svelte';
    import Button from '../atoms/Button.svelte';
    import Input from '../atoms/Input.svelte';
    import { responseStatus, showStore, inputStore, isBroadcastPlateform, settingsStore } from '../../stores/ShowStore';
    import Text from '../atoms/Text.svelte';
    import Paper from '../atoms/Paper.svelte';
    import SubscriptionPlatform from './SubscriptionPlatform.svelte';
    import { sendSubscriber } from '../../api/api';
    import { postMessage, updater } from '../../utils/helpers';
    import { useResponsive } from '../../hooks/useResponsive';
    import { t } from '../../utils/i18n';
    import { CONFIG_POST_MESSAGE } from '../../utils/constants';

    const subscribe = async (e) => {
    	await sendSubscriber({
    		showId: $showStore.id,
    		body: `email=${$inputStore}`,
    		update: updater(true)(responseStatus),
    	});
	postMessage(e)(CONFIG_POST_MESSAGE.payload = { isSubscribed: true });
};

    let width;
    let media = {};
    beforeUpdate(() => {
    	media = useResponsive(width);
    });

</script>
<svelte:window bind:innerWidth={width}/>
<svelte:component this={SubscriptionPlatform}/>
{#if $showStore.subscribe}
    <Paper position="relative" w="100%" flex wrap>
        <Text
                size="big"
                weight="bold"
                as="p"
                label={$t("s abonner à la newsletter")}
                align="center"
                w="max-content"
                m="auto"
                background="white"
                px='20px'
        />
        <Text position="absolute" top="50%" translateX="-50%" left="50%" z={-1} as="hr" h="1px" w="100%"
              background="greyLight" label=""/>
    </Paper>
    <form on:submit|preventDefault={e => subscribe(e)}>
        <Input value mt="25px" mb="15px" mx="auto"/>
        <Button disabled={!$inputStore} type="submit" accent="primary" isSettingColor>
            <Text weight="semiBold" color="white" label={$t("je m abonne")}/>
        </Button>
        <Text
                align="center"
                size="small"
                as="p"
                py="25px"
                label={$t("en vous abonnant, vous acceptez de recevoir des e-mails de votre podcasteur favori (Ausha ne vous enverra pas de mail).")}/>
    </form>
{/if}
