import { BROADCAST_PLATEFORMS } from './constants';


export const broadcastListNameConverter = name => {
	return BROADCAST_PLATEFORMS[name] || name;
};

export const useToggleClass = ({ on, off, switchOn, onProps }) => {
	if (switchOn && onProps === on) return off;
	if (!switchOn && onProps === off) return on;
	else return onProps;
};

export const extractQuery = query => {
	const splitQuery = query.split(/(\w+=[-.a-zA-Z0-9]+)/gm);
	const cleanParams = splitQuery.filter(q => q.match(/[^?^&]+/));
	const params = cleanParams.map(params => params.split('='));
	return Object.assign(Object.fromEntries(params), { path: query });
};

export const buildQuery = obj => target => {
	const newObjQuery = Object.assign(obj, target);
	delete newObjQuery.path;
	const buildUrl = Object.entries(newObjQuery).map(c => c.join('=')).join('&');
	return `?${buildUrl}`;
};

export const updater = updateValue => store => {
	return store.update(() => updateValue);
};

export const closeModal = modalStore => () => {
	updater(false)(modalStore);
};

export const postMessage = event => data => {
	event.preventDefault();
	parent.postMessage(data, '*');
};
