import emotion from 'emotion/dist/emotion.umd.min.js';

const colorsPalette = {
	white: '#ffffff',
	greyLight: '#f3f3f3',
	grey: '#9d9aab',
	black: '#1B1A17',
	blackOpacity: '#1b1a17bf',
};

const transition = {
	primary: 'all ease-in-out .3s',
};

const { css } = emotion;

export const styles = {
	text: {
		center: css`
      text-align: center;
    `,
		right: css`
      text-align: right;
    `,
		left: css`
      text-align: left;
    `,
	},
	colors: {
		white: css`
      color: ${colorsPalette.white};
    `,
		greyLight: css`
      color: ${colorsPalette.greyLight};
    `,
		grey: css`
      color: ${colorsPalette.grey};
    `,
		black: css`
      color: ${colorsPalette.black};
    `,
	},
	backgroundColor: {
		white: css`
      background: ${colorsPalette.white};
    `,
		greyLight: css`
      background: ${colorsPalette.greyLight};
    `,
		grey: css`
      background: ${colorsPalette.grey};
    `,
		black: css`
      background: ${colorsPalette.black};
    `,
		blackOpacity: css`
      background: ${colorsPalette.blackOpacity};
    `,
	},
	sizes: {
		big: css`
    		font-size: 21px;
    `,
		normal: css`
    		font-size: 14px;
    `,
		small: css`
    		font-size: 13px;
    `,
		verySmall: css`
			font-size: 12px;
    `,
	},
	fonts: {
		fontFamily: css`
      	font-family: 'Open Sans', arial, sans-serif;
    `,
		weight: {
			bold: css`
        font-weight: 800;
      `,
			normal: css`
        font-weight: 400;
      `,
			semiBold: css`
        font-weight: 600;
      `,
		},
	},
	image: {
		small: css`
			width: 60px;
			height: 60px;
			min-width: 60px;
			min-height: 60px;
		`,
		normal: css`
			width: 120px;
			height: 120px;
			min-width: 120px;
			min-height: 120px;
		`,
	},
	shadowAccents: {
		normal: css`
			box-shadow: 0 0 5px rgba(0,0,0,0.15);
			transition: ${transition.primary};
			&:hover {
				box-shadow: 0 5px 16px rgba(0,0,0,0.19);
			}
		`,
	},
	borderRadius: {
		normal: css`
      border-radius: 4px;
    `,
		rounded: css`
      border-radius: 50%;
    `,
	},
	accents: {
		primary: css`
            border-radius: 4px!important;
            color: ${colorsPalette.white};
            border: none;
            padding: 12px 0!important;
            width: 100%;
            cursor: pointer;
            display: flex;
            justify-content: center;
    `,
		secondary: css`
			border-radius: 50px!important;
            color: ${colorsPalette.grey};
            padding: 0px 25px!important;
            cursor: pointer;
            border: none;
            font-size: 12px;
            height: 33px;
            background: ${colorsPalette.greyLight};
	`,
		icon: css`
            border-radius: 50%!important;
            border: none;
            padding: 12px 0;
            cursor: pointer;
            max-width: 40px;
            min-height: 40px;
            width: 40px;
            height: 40px;
            background: ${colorsPalette.greyLight};
            > div svg {
            	fill: ${colorsPalette.black};
            	max-width: 14px;
				min-height: 14px;
				width: 14px;
				height: 14px;
            }
    `,
		iconResp: css`
            border-radius: 50%!important;
            border: none;
            padding: 12px 0;
            cursor: pointer;
            max-width: 32px;
            min-height: 32px;
            width: 32px;
            height: 32px;
            background: ${colorsPalette.greyLight};
            > div svg {
            	fill: ${colorsPalette.black};
            	max-width: 11px;
				min-height: 11px;
				width: 11px;
				height: 11px;
            }
    `,
	},
};

const cssProps = (r, { ...props }) => {
	const { colors } = styles;
	return {
		[r]: css`
		display: ${(props.flex && `flex`) || props.display};
		flex-wrap: ${props.wrap};
		white-space: ${props.noWrap && 'nowrap'};
        position: ${props.position};
        top: ${props.top};
        right: ${props.right};
        bottom: ${props.bottom};
        left: ${props.left};
        z-index: ${props.z};
        width: ${props.w || props.size};
        height: ${props.h || props.size};
        min-width: ${props.minW};
        min-height: ${props.minH};
        max-height: ${props.maxH};
        max-width: ${props.maxW};
        padding: ${props.pt || props.py || props.p || 0} ${props.pr ||
		props.px ||
		props.p || 0} ${props.pb || props.py || props.p || 0} ${props.pl ||
		props.px ||
		props.p || 0};
        margin: ${props.mt || props.my || props.m || 0} ${props.mr ||
		props.mx ||
		props.m || 0} ${props.mb || props.my || props.m || 0} ${props.ml ||
		props.mx ||
		props.m || 0};
        border-radius: ${props.radTL || props.radT || props.rad || undefined} ${props.radTR || props.radT || props.rad || undefined} ${props.radBR || props.radB || props.rad || undefined} ${props.radBL || props.radB || props.rad || undefined};
        color: ${colors[props.color] || props.color} ${props.as === "a" && '!important'};
        fill: ${colors[props.color]};
        opacity: ${props.opacity || props.disabled && 0.7};
        justify-content: ${props.justify};
        align-items: ${props.align};
        flex-wrap: ${props.wrap && 'wrap'};
        flex-direction: ${props.flexDirection && 'column'};
        background: ${props.background};
        text-align: ${props.align};
        text-transform: ${props.capitalize && 'capitalize'};
        transform: translate(${props.translateX || props.translate || 0}, ${props.translateY || props.translate || 0});
		box-sizing: border-box;
			label {
				width: 100%;
				height: 100%;
				input {
					display: block;
					width: 100%;
					height: 100%;
					margin: 0;
					border: 1px solid rgba(27, 26, 23, 0.15);
					border-radius: 4px;
					font-size: 14px;
					padding: 15px;
					&::placeholder {
						color: #9D9AAB;
						font-size: 14px;
					}
					&:invalid {
						border: 1px solid red;
					}
					&:focus {
						outline: none;
					}
				}
			}
		`,
	};
};

export const reflex = props => {
	const cssClass = cssProps('reflex', { ...props });
	const { accents, backgroundColor, borderRadius, shadowAccents } = styles;
	const resultStyle = `${cssClass.reflex} ${accents[props.accent]} ${backgroundColor[props.background]} ${borderRadius[props.borderRadius]} ${shadowAccents[props.shadow]}`;
	return resultStyle.replace(/\s(undefined)+/g, '').trim();
};
