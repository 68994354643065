import close from './icons/close.svg';
import validate from './icons/validate.svg';
import deezer from './icons/deezer.svg';
import google_podcasts from './icons/googlePodcast.svg';
import spotify from './icons/spotify.svg';
import youtube from './icons/youtube.svg';
import itunes from './icons/ApplePodcasts.svg';
import castbox from './icons/castbox.svg';
import castro from './icons/castro.svg';
import overcast from './icons/overcast.svg';
import pocket_casts from './icons/pocketCasts.svg';
import soundcloud from './icons/soundcloud.svg';
import stitcher from './icons/stitcher.svg';
import tunein from './icons/tunein.svg';
import podcast_addict from './icons/podcastAddict.svg';
import rss from './icons/rss.svg';
import amazon from './icons/AmazonMusic.svg';

export const rootIcons = icon => {
	const icons = {
		close,
		validate,
		deezer,
		google_podcasts,
		spotify,
		youtube,
		itunes,
		castbox,
		castro,
		overcast,
		pocket_casts,
		soundcloud,
		stitcher,
		tunein,
		podcast_addict,
		rss,
		amazon
	};
	return icons[icon];
};