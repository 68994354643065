<script>
    import { beforeUpdate } from 'svelte';
    import { styles, reflex } from '../../utils/styles.js';

    const { backgroundColor, borderRadius, shadowAccents } = styles;
    export let background = backgroundColor.white;
    export let radius = borderRadius.rounded;
    export let shadow = shadowAccents.shadow;

    let props = {};
    let style = {};
    beforeUpdate(() => {
        style = reflex({ ...props, backgroundColor: background, borderRadius: radius, shadowAccents: shadow });
        props = $$props;
    });

</script>
<div class={style} id={$$props.id}>
    <slot/>
</div>
