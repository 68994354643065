<script>
    import { styles, reflex } from '../../utils/styles';
    import { settingsStore } from '../../stores/ShowStore';
    import { beforeUpdate, onMount } from 'svelte';
    import { useResponsive } from '../../hooks/useResponsive';
    import { useToggleClass } from '../../utils/helpers';

    const { accents } = styles;
    export let accent = accents.primary;
    export let icon = false;

    let width;
    let style = {};
    beforeUpdate(() => {
        let media = useResponsive(width);
        accent = useToggleClass({ on: 'icon', off: 'iconResp', switchOn: media.isMobile, onProps: accent});
        style = reflex({ ...$$props, accent });
    });
</script>

<style>
    button {
        transition: ease-in-out 250ms;
    }
</style>
<svelte:window bind:innerWidth={width}/>
{#if icon}
    <button class={style} id={$$props.id} disabled={$$props.disabled} on:click>
        <slot/>
    </button>
{:else if $$props.isSettingColor}
    <button class={style}
            disabled={$$props.disabled}
            id={$$props.id}
            on:click
            style="background: {$settingsStore.color}">
        <slot/>
    </button>
{:else}
    <button class={style}
            disabled={$$props.disabled}
            id={$$props.id}
            on:click>
        <slot/>
    </button>
{/if}
