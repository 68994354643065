const HEADERS = {
	'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
};
const isPOSTMethod = body => async res => body.method === 'POST' ? res : (await res.json()).data;
const bodyFactory = ({ method, body }) => ({ method, body, headers: HEADERS });


export const fetcher = async (url, body = { method: 'GET' }, error, update) => {
	try {
		const res = await fetch(process.env.API_URL_PUBLIC + url, bodyFactory(body));
		update && update();
		return await isPOSTMethod(body)(res);
	} catch (e) {
		console.error(e, 'ERROR MESSAGE PASS ON FETCH', error);
		return error || e;
	}
};