<script>
    import { createEventDispatcher, onDestroy, afterUpdate } from 'svelte';
    import { modalStore, showStore, locationStore, useCloseModal } from '../../stores/ShowStore';
    import { fade } from 'svelte/transition';
    import Icon from '../atoms/Icon.svelte';
    import Button from '../atoms/Button.svelte';
    import Paper from '../atoms/Paper.svelte';
    import { useResponsive } from '../../hooks/useResponsive';

    let modal;

    const dispatch = createEventDispatcher();
    const close = () => dispatch('close');

    const handle_keydown = e => {
        if (e.key === 'Escape') {
            close();
            return;
        }

        if (e.key === 'Tab') {
            // trap focus
            const nodes = modal.querySelectorAll('*');
            const tabbable = Array.from(nodes).filter(n => n.tabIndex >= 0);

            let index = tabbable.indexOf(document.activeElement);
            if (index === -1 && e.shiftKey) index = 0;

            index += tabbable.length + (e.shiftKey ? -1 : 1);
            index %= tabbable.length;

            tabbable[index].focus();
            e.preventDefault();
        }
    };

    const previously_focused =
            typeof document !== 'undefined' && document.activeElement;

    if (previously_focused) {
        onDestroy(() => {
            previously_focused.focus();
        });
    }

    let width;
    let media = {};
    afterUpdate(() => {
        media = useResponsive(width);
    });
</script>

<style>
    .modal {
        max-width: 600px;
        min-height: auto;
        border-radius: 4px;
        position: relative;
        background: white;
        transition: ease-in-out .25s;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    .modal-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
    }

</style>
<svelte:window bind:innerWidth={width}/>
{#if $modalStore}
    <div class="modal-background" on:click={close}></div>
    <div style="width: {media.isMobileOrTablet ? 'calc(100% - 40px)' : '100%'}" class="modal" role="dialog" bind:this={modal}
         in:fade={{ duration: 100 }} out:fade={{ duration: 250 }}>
        <Button accent=icon icon position=absolute z="1" top=0 right=0 m=20px on:click={e => useCloseModal(e)}>
            <Icon as="close"/>
        </Button>
        <slot>
            <em>no content was provided</em>
        </slot>
    </div>
{/if}
