import { reflex } from './styles';
import { TAG_NAME } from './constants';

export const classFactory = (...args) => {
	const removeUndefined = args.filter(clean => clean);
	return removeUndefined.join(' ');
};

export const tagsFactory = ({ ...props }) => {
	let tags = {};
	const style = reflex({ ...props });
	const ID = props.id || '';
	const resultClass = props.result + ' ' + style;
	TAG_NAME.map(
		c =>
			(tags = {
				...tags,
				[c]: `<${c} class='${resultClass}' ${ID && `id=${ID}`} ${
					c === 'a' ? `href=${props.href} target="_blank"` : ''
				}>${props.label}</${c}>`,
			}),
	);
	return tags;
};