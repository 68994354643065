<script>
    import { rootIcons } from '../../../static/assets/RootIcons';
    import { reflex } from '../../utils/styles';
    import Text from './Text.svelte';

    const as = $$props.as;
    const icon = rootIcons(as);
    const href = $$props.href;
    const style = reflex({ ...$$props });
    const src = `/broadcast-platforms/${as}.png`;
</script>

{#if href}
    <a target="_blank" {href} class={style}>
		{#if icon}
		    {@html icon}
		{:else}
		    <img {src} alt="" style="max-width: 100%; max-height: 100%;" />
		{/if}
    </a>
{:else}
    <div class={style}>
		{@html icon}
    </div>
{/if}
