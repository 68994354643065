<script>
    import Text from '../atoms/Text.svelte';
    import Button from '../atoms/Button.svelte';
    import Icon from '../atoms/Icon.svelte';
    import { inputStore, responseStatus, useCloseModal } from '../../stores/ShowStore';
    import { fly, draw, fade } from 'svelte/transition';
    import { quintOut, backOut } from 'svelte/easing';
    import SubscriptionPlatform from './SubscriptionPlatform.svelte';
    import Paper from '../atoms/Paper.svelte';
    import { updater } from '../../utils/helpers';
    import { t } from '../../utils/i18n';

    function resetModal(e) {
        useCloseModal(e);
        setTimeout(() => {
            updater(false)(responseStatus);
            inputStore.set('');
        }, 500);
    }
</script>

<svelte:component this={SubscriptionPlatform}/>
<div transition:fly={{y: 20, duration: 450}}>
    <Paper m="auto">
        <Paper mb="10px" position="relative" w="100%" flex wrap justify="center">
            <Text px="20px" background="white" weight="bold" size="big" label={$t("merci !")} align="center" noWrap/>
            <Text position="absolute" top="50%" translateX="-50%" left="50%" z={-1} as="hr" h="1px" w="100%"
                  background="greyLight" label=""/>
        </Paper>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="66px" x="0px" y="0px"
             viewBox="0 0 100 100" style="enable-background:new 0 0 100 100">
            <g in:fade={{delay: 400, duration: 250}}>
                <circle style="opacity:0.25;fill:#2ED573" cx="50" cy="50.2" r="49.9"/>
                <circle style="fill:#2ED573" cx="50" cy="50.2" r="37.5"/>
                <polyline
                        in:draw="{{delay: 500, duration: 350, easing: backOut}}"
                        style="fill:none;stroke:#FFFFFF;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"
                        points="29.5,49.9 45.4,62.4 68.9,35.8"/>
            </g>
        </svg>
    </Paper>

    <div transition:fly={{delay: 100, y: 20, duration: 450}}>
        <Text mt="10px" size="small" label={$t("un email de confirmation d’inscription a été envoyé à l’adresse.")}
              align="center"/>
        <Text weight="bold" size="small" label={$inputStore} mb="25px" align="center"/>
    </div>
</div>
