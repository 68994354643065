<script>
    import { beforeUpdate, onMount } from 'svelte';
    import { showStore } from '../../stores/ShowStore';
    import Icon from '../atoms/Icon.svelte';
    import Paper from '../atoms/Paper.svelte';
    import Tooltip from './Tooltip.svelte';
    import Text from '../atoms/Text.svelte';
    import { t } from '../../utils/i18n';
    import { useResponsive } from '../../hooks/useResponsive';

    let width;
    let media = {};

    onMount(() => {
        media = useResponsive(width);
	});

    beforeUpdate(() => {
        media = useResponsive(width);
    });

</script>

<svelte:window bind:innerWidth={width}/>
<Text
        align="center"
        as="p"
        color="grey"
        label={$showStore.name}
        mb="5px"
        weight="semiBold"
        mt={media.isMobile ? '50px' : '80px'}
/>
<Text align="center" label={$t("s abonner")} weight="bold" size="big" mb="20px"/>
<Paper flex wrap justify="center" mb="36px">
    <Tooltip message={$t("flux rss")} w="50px" h="50px" flex justify="center" align="center" radius="normal"
             shadow="normal" m="5px"
             minW="50px" mb="5px">
        <Icon w="100%" as='rss' href={$showStore.rss_link} p="10px"/>
    </Tooltip>
	{#each $showStore.listening_links.data as {key, url}}
        <Tooltip message={key} w="50px" h="50px" flex justify="center" align="center" radius="normal"
                    shadow="normal" m="5px"
                    minW="50px" mb="5px">
            <Icon w="100%" as={key} href={url} p="10px"/>
        </Tooltip>
	{/each}
</Paper>