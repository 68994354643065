<script>
    import { fly, draw, fade } from 'svelte/transition';
    import { reflex, styles } from '../../utils/styles';
    import { broadcastListNameConverter } from '../../../src/utils/helpers';
    import Paper from '../atoms/Paper.svelte';
    import Text from '../atoms/Text.svelte';

    const { backgroundColor, borderRadius, shadowAccents } = styles;
    export let background = backgroundColor.white;
    export let radius = borderRadius.rounded;
    export let shadow = shadowAccents.shadow;
    let active = false;
    const style = reflex({ ...$$props, backgroundColor: background, borderRadius: radius, shadowAccents: shadow });

    const setActive = bool => active = bool;
</script>

<style type="text/scss">
    div {
        position: relative;
    }
    .wrap-tooltip {
        position: absolute;
        top: -100%;
    }
    .wrap-tooltip:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        transform: translate(-50%, 100%);
        left: 50%;
        border-top: 4px solid #1b1a17bf;
        border-left: 4px solid #1b1a17bf;
        border-right: 4px solid #1b1a17bf;
        border-left-color: transparent;
        border-bottom: transparent;
        border-right-color: transparent;
    }
</style>

<div class={style} on:mouseover={() => setActive(true)} on:mouseleave={() => setActive(false)}>
	{#if active}
        <div class="wrap-tooltip" transition:fly={{y: 20, duration: 450}}>
            <Text size="small" capitalize flex as="span" w="max-content" radius="normal" background="blackOpacity" color="white"
                  p="8px"
                  z="10" label={broadcastListNameConverter($$props.message)}>
            </Text>
        </div>
	{/if}
    <slot/>
</div>